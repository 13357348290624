import React from 'react';
import { FormGroup, Label, Input, FormFeedback} from 'reactstrap';

const RadioWidget = React.forwardRef(({ name, displayName, error, json, value, onChange, ...rest }, ref) => {
  return (
    <React.Fragment>
      {/* <FormGroup>
        <Label className="mb-2">{ displayName || name }</Label>
      <div style={{ display: 'flex' }}>
      {
        json?.options && json.options.length > 0 &&
        json.options.map((item, index) => (
          <FormGroup key={`radio_name_${index}`} className="mr-2" check>
            <Label check>
              <Input invalid={!!error} type="radio" ref={ref} name={name} value={item.value} {...rest} />{' '}
              { item.label } 
            </Label>
          </FormGroup>
        ))
      }
      {
        json?.type === 'Boolean' &&
        ['True', 'False'].map((item, index) => (
          <FormGroup key={`radio_name_${index}`} style={{ marginRight: '10px' }} check>
            <Label check>
              <input invalid={!!error} type="radio" ref={ref} name={name} value={item} {...rest} />{' '}
              { item  } 
            </Label>
          </FormGroup>
        ))
      } 
      {
        error &&
        <FormFeedback>{ error.message }</FormFeedback>
      }
      </div>
      </FormGroup> */}

      <FormGroup tag="fieldset" value={value} onChange={onChange} style={json?.style || {}}>
        <Label className="mb-2">{ displayName || name }</Label>
        <div style={{ display: 'flex' }}>
          {
            json?.options && json.options.length > 0 &&
            json.options.map((item, index) => (
              <FormGroup check key={`radio_name_${index}`} className="mr-2">
                <Label check>
                  <Input invalid={!!error} name={name} ref={ref} type="radio"  value={item.value} />
                  { item.label }
                </Label>
              </FormGroup>
            ))
          }
          {
            json?.type === 'Boolean' &&
            [true, false].map((item, index) => (
              <FormGroup key={`radio_name_${index}`} style={{ marginRight: '10px' }} check>
                <Label check>
                  <Input invalid={!!error}  type="radio" ref={ref} name={name} value={item} />{' '}
                  { item ? 'True' : 'False' } 
                </Label>
              </FormGroup>
            ))
          } 
        </div>
      </FormGroup>
    </React.Fragment>
  );
});

export default RadioWidget;
