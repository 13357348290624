import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const InputWidget = React.forwardRef(({ name, displayName, error, json, ...rest }, ref) => {

  return (
    <FormGroup style={json?.style || {}}>
      <Label className="mb-2">{ displayName || name }</Label>
      <Input invalid={!!error} type={name.toLowerCase() === 'password' ? 'password' : 'text'} name={name}  placeholder={displayName || name} {...rest} ref={ref} />
      {
        error &&
        <FormFeedback>{ error.message }</FormFeedback>
      }
    </FormGroup>
  )
});

export default InputWidget;
