import React from 'react';
import {FormGenerate} from '@dhiwise/react-json-form';
import InputWidget from './components/InputWidget';
import FormWidget from './components/FormWidget'
import CheckboxWidget from './components/CheckboxWidget';
import SelectWidget from './components/SelectWidget';
import DatePickerWidget from './components/DatePickerWidget';
import DateTimePickerWidget from './components/DateTimePickerWidget';
import RadioWidget from './components/RadioWidget';
import NumberWidget from './components/NumberWidget';
import PercentageWidget from './components/PercentageWidget';
import ButtonWidget from './components/ButtonWidget';
import EmailWidget from './components/EmailWidget';
import TextAreaWidget from './components/TextAreaWidget';
import { jsx, ThemeProvider } from '@emotion/react';
import UrlWidget from './components/UrlWidget';
import UploadWidget from './components/UploadWidget';
import CodeWidget from './components/CodeWidget';
import AsyncSelect from './components/AsyncSelect';
import { useForm, FormProvider } from 'react-hook-form';

const theme = {
  dark: {
    colors: {
      primary: '#4466F2',
      secondary: '#FFF',
      icon: "#FFFFFF",
      focus: '#143FEF',
    },
    backgroundColor: '#212121',
    borderColor: '#282828',
    inputBorder:'#4e4e4e',
    textColor: 'rgba(255, 255, 255, 0.85)',
    inputBackground:"#282828",
  },
  light: {
    colors: {
      primary: '#4466F2',
      secondary: '#FFF',
      icon: "#232323",
      focus: '#143FEF',
    },
    backgroundColor: '#FFF',
    borderColor: '#ced4da',
    inputBorder:'#dadada',
    textColor:'#495057',
    inputBackground:"#f7f7f7",
  },
  
}

const ThemedForm = ({ schema, customWidgets, onSubmit, formRef, defaultValues={}, loadOptions, handleUpload, themeMode = 'light' }) => {

  const methods = useForm();

  const widgets = {
    InputWidget,
    TextWidget: InputWidget,
    CheckboxWidget,
    DateWidget: DatePickerWidget,
    DatePicker: DatePickerWidget,
    DateTimePicker: DateTimePickerWidget,
    FormWidget,
    SelectWidget,
    RadioWidget,
    NumberWidget,
    PercentageWidget,
    ButtonWidget,
    EmailWidget,
    TextAreaWidget,
    UrlInput: UrlWidget,
    CodeWidget,
    UploadWidget,
    AsyncSelect,
    ...customWidgets,
  };

  return(
    <ThemeProvider theme={theme}>
      <FormProvider {...methods} >
        <FormGenerate
          formMethods={{...methods}}
          handleUpload={handleUpload}
          loadOptions={loadOptions}
          widgets={widgets}
          schema={schema}
          themeMode={themeMode}
          onSubmit={onSubmit}
          formRef={formRef}
          defaultValues={defaultValues}
        />
      </FormProvider>
    </ThemeProvider>
  );
};

export default ThemedForm;
