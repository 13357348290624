import React from "react";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import AsyncSelect from "react-select/async";
import { Controller } from "react-hook-form";
import { useTheme } from "@emotion/react";
import { validationKeys } from "../../constants";
import { formatLabel } from "../../utils";
import { getValidationData } from "../../utils/getValidationData";
import { getWatchFields, shouldDisplayInput } from "../../utils/filterData";

const options = [{ name: "test", id: 1 }];
const RefWrapper = ({ json, loadOptions, control, themeMode,formMethods }) => {
  const theme = useTheme();

  const customStyle = {
    control: (provided) => ({
      ...provided,
      border: `1px solid ${theme[themeMode]?.inputBorder}`,
      color: `${theme[themeMode]?.textColor}`,
      backgroundColor: `${theme[themeMode]?.backgroundColor}`,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: `${theme[themeMode]?.textColor}`,
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: `${theme[themeMode]?.backgroundColor}`,
    }),
    input: (provided) => ({
      ...provided,
      color: `${theme[themeMode]?.textColor}`,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isHovered
        ? `${theme[themeMode]?.colors?.primary} !important`
        : state.isFocused
        ? `${theme[themeMode]?.colors?.primary} !important`
        : state.isSelected
        ? `${theme[themeMode]?.colors?.primary} !important`
        : "transparent !important",
      color: state.isHovered
        ? `#fff`
        : state.isFocused
        ? `#fff`
        : `${theme[themeMode]?.textColor}`,
    }),
  };

  const {watch,getValues} = formMethods;
  const { showData, hideData, watchFields } = getWatchFields(json);
  const watchedFields = watch(watchFields || ['dummy']);

  const show = React.useMemo(
    () => shouldDisplayInput({
        displayOptions:json?.displayOptions,
        showData,
        hideData,
        getValues
      }),
    [watchedFields,showData,hideData,json,getValues],
  );

  if(!show) return <React.Fragment></React.Fragment>
  return (
    <Controller
      control={control}
      name={json?.attrName}
      rules={{
        ...getValidationData(json),
      }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error },
      }) => (
        <FormGroup className="fromSelect">
          <Label className="mb-2">{formatLabel(json?.labelOfAttribute || json?.label)}</Label>
          <AsyncSelect
            value={value}
            placeholder={<div>Type to search</div>}
            cacheOptions
            getOptionLabel={(option) => option[json?.displayAttribute]}
            getOptionValue={(option) => option[json?.valueAttribute]}
            loadOptions={(inputValue, callback) =>
              loadOptions(
                { ...json },
                inputValue,
                callback
              )
            }
            styles={customStyle}
            isClearable
            onChange={(data) => {
              onChange(data);
            }}
            {...rest}
          />
          {error && <FormFeedback style={{ display: 'block' }}>{error.message}</FormFeedback>}
        </FormGroup>
      )}
    />
  );
};

export default RefWrapper;
