/* eslint-disable no-unused-expressions */
export default (data, schema) => {
  const errors = {}; 
  Object.keys(data)
    .forEach(formKey => {
      const schemaAttr = schema.attributes.find(attr => attr.attrName === formKey);
      const shouldValidate = schemaAttr?.required;
      if(shouldValidate && !data[formKey]) {
        errors[formKey] = { type: 'manual', message: 'This field must have atleast one entry.' };
      }
      if (schemaAttr && data[formKey] !== undefined && data[formKey] !== "") {
        // Array type check
        if (shouldValidate && (schemaAttr?.type === 'Array' || schemaAttr?.type === 'MultiUpload')) {

          // remove undefined or "" keys
          const filteredData = [];
          if(data[formKey]?.length > 0) {
            data[formKey]?.forEach(item => {
              // Array of object
              if(typeof item === 'object') {
                const newObj = {};
                Object.keys(item).forEach(itemKey => {
                  if(item[itemKey] !== undefined && item[itemKey] !== "") {
                    newObj[itemKey] = item[itemKey];
                  }
                }); 
                if(Object.keys(newObj)?.length > 0) {
                  filteredData.push(newObj);
                }
              } 
              // Array of values
              else if(item !== undefined && item !== "") {
                filteredData.push(item);
              }
            });
          }

          // empty array check
          if (filteredData?.length === 0) {
            errors[formKey] = { type: 'manual', message: 'This field must have atleast one entry.' };
          }
        }
      }
    });

  return errors;
};