import React from "react";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import Select from "react-select";
import { useTheme } from "@emotion/react";

const AsyncSelect = ({ json, loadOptions, value, themeMode, error, ...rest }) => {
  const theme = useTheme();
  const [options, setOptions] = React.useState({
    optionsLoaded: false,
    options: [],
    isLoading: false
  });

  const customStyle = {
    control: (provided) => ({
      ...provided,
      border: `1px solid ${theme[themeMode]?.inputBorder}`,
      color: `${theme[themeMode]?.textColor}`,
      backgroundColor: `${theme[themeMode]?.backgroundColor}`,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: `${theme[themeMode]?.textColor}`,
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: `${theme[themeMode]?.backgroundColor}`,
    }),
    input: (provided) => ({
      ...provided,
      color: `${theme[themeMode]?.textColor}`,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isHovered
        ? `${theme[themeMode]?.colors?.primary} !important`
        : state.isFocused
        ? `${theme[themeMode]?.colors?.primary} !important`
        : state.isSelected
        ? `${theme[themeMode]?.colors?.primary} !important`
        : "transparent !important",
      color: state.isHovered
        ? `#fff`
        : state.isFocused
        ? `#fff`
        : `${theme[themeMode]?.textColor}`,
    }),
  };

  React.useEffect(() => {
    setOptions({ isLoading: true });
    loadOptions({...json}, "", (data) => {
      setOptions({
        optionsLoaded: true,
        options: data,
        isLoading: false
      });
    });
  }, []);

  return (
    <FormGroup className="fromSelect">
      <Label className="mb-2">{json?.displayName || json?.label}</Label>
      <Select
        value={value}
        placeholder={<div>Type to search</div>}
        cacheOptions
        autoload={true}
        isLoading={options.isLoading}
        options={options.options}
        defaultOptions
        getOptionLabel={(option) => option[json?.displayAttribute]}
        getOptionValue={(option) => option[json?.valueAttribute]}
        styles={customStyle}
        isClearable
        {...rest}
      />
      {error && <FormFeedback style={{ display: 'block' }}>{error.message}</FormFeedback>}
    </FormGroup>
  );
};

export default AsyncSelect;

