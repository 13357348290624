import { isJsonSchema } from "./isJsonSchema";

export default (data, schema) => {
  const errors = {}; 
  Object.keys(data)
    .forEach(formKey => {
      const schemaAttr = schema.attributes.find(attr => attr.attrName === formKey);
      const shouldValidate = schemaAttr?.required;
      if(shouldValidate && !data[formKey]) {
        errors[formKey] = { type: 'manual', message: 'Please fill atleast one field.' };
      }

      if (schemaAttr && data[formKey] !== undefined && data[formKey] !== "") {
        // JSON Schema
        if (shouldValidate && isJsonSchema(schemaAttr)) {
          const filteredData = {};
          data[formKey].forEach(item => {
            if(item?.key && item?.key !== "" && item?.value !== undefined && item?.value !== "") {
              filteredData[item.key] = item.value;
            }
          }); 
          if (Object.keys(filteredData)?.length === 0) {
            errors[formKey] = { type: 'manual', message: 'Please fill atleast one key value pair.' };
          }
        }

        // JSON type check
        if (shouldValidate && schemaAttr?.type === 'JSON') {
          
          // remove undefined or "" keys
          const filteredData = {};
          Object.keys(data[formKey]).forEach(item => {
            if(data[formKey][item] !== undefined && data[formKey][item] !== "") {
              filteredData[item] = data[formKey][item];
            }
          }); 

          // empty object check
          if (Object.keys(filteredData)?.length === 0) {
            errors[formKey] = { type: 'manual', message: 'Please fill atleast one field.' };
          }
        }
      }
    });

  return errors;
};