// @ts-nocheck
import React from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import 'react-datepicker/dist/react-datepicker.css'

const DatePickerWidget = React.forwardRef(
  ({ name, displayName, error, json, onChange, value, ...rest }, ref) => {
    return (
      <FormGroup className='datepicker' style={json?.style || {}}>
        <Label className='mb-2'>{displayName || name}</Label>
        <DatePicker
          onChange={onChange}
          selected={value ? new Date(value) : new Date()}
          name={name}
          dateFormat='dd/MM/yyyy h:mm aa'
          timeInputLabel='Time:'
          showTimeInput
          ref={ref}
        />
        {error && <FormFeedback>{error.message}</FormFeedback>}
      </FormGroup>
    )
  }
)

export default DatePickerWidget
