import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const EmailWidget = React.forwardRef(({ name, displayName, error, value, json, ...rest }, ref) => {

  return (
    <FormGroup style={json?.style || {}}>
      <Label className="mb-2">{ displayName || name }</Label>
      <Input invalid={!!error} type="text" name={name}  placeholder={displayName || name}  value={value || ""} {...rest} ref={ref} />
      {
        error &&
        <FormFeedback>{ error.message }</FormFeedback>
      }
    </FormGroup>
  )
});

export default EmailWidget;
