// @ts-nocheck
/* eslint-disable no-unused-expressions */
import React from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import ArrayWrapper from "./widgets/ArrayWrapper";
import { getCols, getWidget } from "../utils";
import FormInputs from "./FormInputs";
import JSONWrapper from "./widgets/JSONWrapper";
import RefWrapper from "./widgets/RefWrapper";
import { DB_TYPE, INVALID_TYPES, validationKeys } from "../constants";
import { filterData } from "../utils/filterData";
import arrayValidation from "../utils/arrayValidation";
import jsonValidation from "../utils/jsonValidation";
import { filterInvalidTypes } from "../utils/filters";
import JSONSchemaWidget from "./widgets/JSONSchemaWidget";
import { isJsonSchema } from "../utils/isJsonSchema";
import { isRelation } from "../utils/isRelation";
import { useLocalFormDispatch } from "../context/localContext";

const FormGenerate = ({
  themeMode,
  formMethods,
  schema,
  widgets,
  onSubmit,
  formRef,
  defaultValues = {},
  loadOptions = () => {},
  handleUpload = () => {},
}) => {
  const { databaseType } = schema;
  
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // clearErrors,
    setError,
    formState: { errors },
    watch,
  } = formMethods;

  const formContext = useFormContext();

  React.useEffect(() => {
    if (Object.keys(defaultValues).length > 0) {
      Object.keys(defaultValues).forEach((key) => {
        if (key.toLowerCase() !== "password" && !INVALID_TYPES.includes(key)) {
          const schemaAttr = schema.attributes.find(
            (attr) => attr.attrName === key
          );

          // JSON Schema
          if (isJsonSchema(schemaAttr) && schemaAttr?.inputType !== 'CodeWidget') {
            let stringVal;
            try {
              stringVal = JSON.parse(defaultValues[key]);
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log("json parse error: ", error);
            }
            if (typeof stringVal === "object") {
              Object.keys(stringVal).forEach((valKey, valIndex) => {
                setValue(`${schemaAttr?.attrName}.${valIndex}.key`, valKey);
                setValue(
                  `${schemaAttr?.attrName}.${valIndex}.value`,
                  stringVal[valKey]
                );
              });
            }
          }

          // JSON objects mapping
          if (schemaAttr && schemaAttr?.type === "JSON") {
            if (typeof defaultValues[key] === "object") {
              
              if (schemaAttr?.inputType === 'CodeWidget') {
                setValue(schemaAttr?.attrName, defaultValues[key]);
                return;
              }
              Object.entries(defaultValues[key]).map(
                ([nestedKey, nestedValue]) => {
                  setValue(`${key}.${nestedKey}`, nestedValue);
                }
              );
            }
            return;
          }

          // Array mapping
          if (schemaAttr && schemaAttr?.type === "Array") {
            if (schemaAttr?.childType && Array.isArray(defaultValues[key])) {
              defaultValues[key]?.forEach((val, index) =>
                setValue(`${key}.${index}`, val)
              );
            } else if (
              schemaAttr?.children &&
              Array.isArray(defaultValues[key])
            ) {
              defaultValues[key]?.forEach((val, index) => {
                if (typeof val === "object") {
                  Object.entries(val).forEach(([nestedKey, nestedValue]) => {
                    setValue(`${key}.${index}.${nestedKey}`, nestedValue);
                  });
                }
              });
            } else if (schemaAttr?.inputType === "MultiUpload") {
              setValue(key, defaultValues[key]);
            }
            return;
          }

          setValue(key, defaultValues[key]);
        }
      });
    }
    return () => { if (formContext?.clearErrors) { formContext?.clearErrors(); } };
  }, [defaultValues]);

  const { FormWidget } = widgets;

  const resetForm = () => {
    const resetData = {};
    schema.attributes.forEach((item) => {
      resetData[item.attrName] = "";
    });
    reset({ ...resetData });
  };

  const customValidations = (data) => {
    const arrayErrors = arrayValidation(data, schema);
    const jsonErrors = jsonValidation(data, schema);
    return {
      ...arrayErrors,
      ...jsonErrors,
    };
  };

  const preSubmit = (data) => {
    // console.log('presubmit: ', data);
    const inputErrors = customValidations(data);
    if (Object.keys(inputErrors)?.length > 0) {
      Object.keys(inputErrors).map((err) => setError(err, inputErrors[err]));
    } else {
      // clearErrors();
      if (formContext?.clearErrors) { formContext?.clearErrors(); }
      onSubmit(filterData(data, schema, databaseType));
    }
  };

  return (
    <FormWidget
      ref={formRef}
      resetForm={resetForm}
      onSubmit={handleSubmit(preSubmit)}
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${getCols(schema)}, minmax(0, 1fr))`,
        gridGap: "1.25rem",
        gap: "1.25rem",
      }}
    >
      {filterInvalidTypes(schema.attributes).map((item, index) => {
        if (item.type === "JSON") {
          if (item?.inputType === "CodeWidget") {
            return (
              <FormInputs
                handleUpload={handleUpload}
                themeMode={themeMode}
                json={item}
                key={`${item.attrName}.input.${index}`}
                formMethods={formMethods}
                Input={
                  widgets[getWidget(databaseType, item)]
                    ? widgets[getWidget(databaseType, item)]
                    : widgets.TextWidget
                }
                control={control}
              />
            );
          }
          if (databaseType === DB_TYPE.MYSQL || databaseType === DB_TYPE.SQL || databaseType === DB_TYPE.POSTGRESQL) {
            return (
              <JSONSchemaWidget
                handleUpload={handleUpload}
                defaultValue={defaultValues[item.attrName] || null}
                error={
                  errors?.[item?.attrName] ? errors?.[item?.attrName] : false
                }
                setValue={setValue}
                themeMode={themeMode}
                screenLayout={schema?.screenLayout}
                widgets={widgets}
                json={item}
                key={`${item.attrName}.input.${index}`}
                control={control}
                formMethods={formMethods}
              />
            );

          }
          return (
            <JSONWrapper
              handleUpload={handleUpload}
              error={
                errors?.[item?.attrName] ? errors?.[item?.attrName] : false
              }
              databaseType={databaseType}
              themeMode={themeMode}
              screenLayout={schema?.screenLayout}
              widgets={widgets}
              json={item}
              key={`${item.attrName}.input.${index}`}
              control={control}
              formMethods={formMethods}
            />
          );
        }
        if (
          item.type === "Array" &&
          item?.inputType !== "SingleUpload" &&
          item?.inputType !== "MultiUpload"
        ) {
          return (
            <ArrayWrapper
              handleUpload={handleUpload}
              setValue={setValue}
              databaseType={databaseType}
              defaultValues={defaultValues[item?.attrName]}
              themeMode={themeMode}
              screenLayout={schema?.screenLayout}
              widgets={widgets}
              json={item}
              key={`${item.attrName}.input.${index}`}
              control={control}
              error={
                errors?.[item?.attrName] ? errors?.[item?.attrName] : false
              }
              formMethods={formMethods}
            />
          );
        }

        if (isRelation(databaseType, item)) {
          if (widgets[item?.inputType]) {
            return (
              <FormInputs
                handleUpload={handleUpload}
                themeMode={themeMode}
                json={item}
                key={`${item.attrName}.input.${index}`}
                Input={widgets[item?.inputType]}
                control={control}
                loadOptions={loadOptions}
                formMethods={formMethods}
              />
            );
          }
          return (
            <RefWrapper
              json={item}
              themeMode={themeMode}
              key={`${item.attrName}.input.${index}`}
              control={control}
              loadOptions={loadOptions}
              formMethods={formMethods}
            />
          );
        }
        return (
          <FormInputs
            handleUpload={handleUpload}
            themeMode={themeMode}
            json={item}
            key={`${item.attrName}.input.${index}`}
            formMethods={formMethods}
            Input={
              widgets[getWidget(databaseType, item)]
                ? widgets[getWidget(databaseType, item)]
                : widgets.TextWidget
            }
            control={control}
          />
        );
      })}
    </FormWidget>
  );
};

export default FormGenerate;
