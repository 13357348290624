import React from 'react'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const PercentageWidget = React.forwardRef(({ name, displayName, error, onChange, json, ...rest }, ref) => {

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const reg = new RegExp('^[0-9]+$');
    if ((reg.test(val) && val <= 100 && val >= 0) || val === "") {
      onChange(e);
    }
    console.log('handleChange data: ', e.target.value, reg.test(val));
  };

  return (
    <FormGroup style={json?.style || {}}>
      <Label className="mb-2">{ displayName || name }</Label>
      <Input invalid={!!error} type="number" name={name}  placeholder={displayName || name} onChange={handleChange} {...rest} ref={ref} />
      {
        error &&
        <FormFeedback>{ error.message }</FormFeedback>
      }
    </FormGroup>
  )
});

export default PercentageWidget;
