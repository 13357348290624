/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// @ts-nocheck
/* eslint-disable no-nested-ternary */

import React from "react";
import { css, cx } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { useFormContext } from "react-hook-form";
import { formatLabel } from "../../utils";
import FormInputs from "../FormInputs";

const JSONSchemaWidget = ({
  json,
  control,
  screenLayout,
  widgets,
  themeMode,
  error,
  defaultValue,
  formMethods
}) => {
  const theme = useTheme();
  const formContext = useFormContext();
  
  const setValue = formContext?.setValue ? formContext?.setValue : () => {};
  const clearErrors = formContext?.clearErrors ? formContext?.clearErrors : () => {};
  const getValues = formContext?.getValues ? formContext?.getValues : () => {};
  const [jsonObj, setJsonObj] = React.useState([]);

  React.useEffect(() => {
    if (!defaultValue) {
      initObject();
    } else {
      setDefaultValues(defaultValue);
    }
  }, [defaultValue]);

  function customValidations() {
    if (error && jsonObj?.length > 0) {
      const filteredData = {};
      const formData = getValues(json?.attrName);
      formData.forEach(item => {
        if(item?.key && item?.key !== "" && item?.value !== undefined && item?.value !== "") {
          filteredData[item.key] = item.value;
        }
      }); 
      if (Object.keys(filteredData)?.length > 0) {
        clearErrors(json?.attrName);
      }
    }
  }

  function setDefaultValues(defaultValueString) {
    let defaultValuesObject;

    try {
      defaultValuesObject = JSON.parse(defaultValueString);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('json parse error: ', err);
    }

    if (defaultValuesObject && Object.keys(defaultValuesObject).length > 0) {
      const newState = Object.keys(defaultValuesObject).map((itemKey, itemIndex) => ({
        key: `${json?.attrName}.${itemIndex}.key`,
        value: `${json?.attrName}.${itemIndex}.value`,
      }));
      setJsonObj(newState);
    }
  }

  function initObject() {
    setJsonObj([
      {
        key: `${json?.attrName}.0.key`,
        value: `${json?.attrName}.0.value`,
      },
    ]);
  }

  const addItem = () => {
    const lastIndex = jsonObj.length;
    setJsonObj((oldValues) => [
      ...oldValues,
      {
        key: `${json?.attrName}.${lastIndex}.key`,
        value: `${json?.attrName}.${lastIndex}.value`,
      },
    ]);
  };

  const removeItem = (index) => {
    if (jsonObj.length === 1) {
      return;
    }

    // Set value in json form
    setValue(`${json?.attrName}.${index}.key`, '');
    setValue(`${json?.attrName}.${index}.value`, '');

    setJsonObj((olditems) => {
      const updateditems = [...olditems];
      updateditems.splice(index, 1);
      return updateditems;
    });
  };

  // STYLES
  const baseParentClass =
    screenLayout?.singlePageForm === "threeLine"
      ? "col-span-3"
      : screenLayout?.singlePageForm === "doubleLine"
      ? "col-span-2"
      : "";

  return (
    <div
      className={`${baseParentClass} ${css`
        margin-top: ${!json?.childType ? "23px" : "0px"};
        display: grid;
        gap: 0.75rem;
        border: 1px solid ${error ? "#dc3545" : theme[themeMode]?.borderColor};
        padding: 10px;
        border-radius: 0.25rem;
        grid-column: ${screenLayout?.singlePageForm === "threeLine"
          ? "span 3 / span 3;"
          : screenLayout?.singlePageForm === "doubleLine"
          ? "span 2 / span 2;"
          : ""};
      `}`}
    >
      <h2
        className={css`
          font-size: 20px;
          margin-top: -23px;
          background: ${theme[themeMode]?.backgroundColor};
          display: inline-block;
          width: max-content;
          padding: 0 10px;
        `}
      >
        {formatLabel(json?.attrName)}
      </h2>

      {error && (
        <p
          className={css`
            font-size: 0.875em;
            margin-top: 0;
            margin-bottom: 0;
            color: #dc3545;
          `}
        >
          {error.message}
        </p>
      )}

      {jsonObj.length > 0
        ? jsonObj.map((item, index) => (
            <div
              className={css`
                display: grid;
                grid-template-columns: repeat(2, minmax(0px, 1fr));
                gap: 1.25rem;
                position: relative;
                padding-right: 70px;
              `}
              key={`${json.attrName}.${index}`}
            >
              {Object.keys(item).map((field) => (
                <FormInputs
                  customValidation={customValidations}
                  json={{
                    type: "String",
                    attrName: item[field],
                    label: field,
                  }}
                  key={item[field]}
                  Input={widgets.InputWidget}
                  control={control}
                  formMethods={formMethods}
                />
              ))}
              <div
                onClick={() => removeItem(index)}
                className={css`
                  width: 26px;
                  height: 26px;
                  position: absolute;
                  right: 30px;
                  border: 1px solid ${theme[themeMode]?.borderColor};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  bottom: 0px;
                  border-radius: 0.25rem;
                  font-size: 12px;
                  cursor: pointer;
                `}
              >
                X
              </div>
              {index === jsonObj.length - 1 && (
                <div
                  type="button"
                  onClick={addItem}
                  className={css`
                    width: 26px;
                    height: 26px;
                    position: absolute;
                    right: 0;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    bottom: 0;
                    border-radius: 0.25rem;
                    font-size: 16px;
                    cursor: pointer;
                    &:focus {
                      background-color: ${theme[themeMode]?.colors
                        ?.focus} !important; // doesn't work
                      outline: none;
                    }
                    background: ${theme[themeMode]?.colors?.primary};
                    color: ${theme[themeMode]?.colors?.secondary};
                  `}
                >
                  +
                </div>
              )}
            </div>
          ))
        : null}
    </div>
  );
};

export default JSONSchemaWidget;
