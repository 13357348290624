import React from 'react'
import { Button, Form} from 'reactstrap';

const FormWidget = React.forwardRef(({ children, className, onSubmit, resetForm, noScroll, ...rest }, ref) => {

  React.useImperativeHandle(ref, () => ({
    submit() {
      onSubmit();
    },
    resetForm() {
      resetForm();
    }
  }));

  return (
    <Form className={`${className}`} ref={ref} onSubmit={onSubmit} {...rest}>
      { children } 
    </Form>
  )
});

export default FormWidget;
