import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { css, cx } from "@emotion/css";
import Editor from '@monaco-editor/react';
import { useFormContext } from 'react-hook-form';

const CodeWidget = React.forwardRef(({ name, displayName, error, value, onChange, themeMode, json, ...rest }, ref) => {

  function editorDidMount(editor) {
    setTimeout(function() {
      editor.getAction('editor.action.formatDocument').run();
    }, 300);
  }
    const val = React.useRef({});
    const formContext = useFormContext();
    const setError = formContext?.setError ? formContext?.setError : () => {};
    const setValue = formContext?.setValue ? formContext?.setValue : () => {};
    const clearErrors = formContext?.clearErrors ? formContext?.clearErrors : () => {};

  return (
    <FormGroup style={json?.style || {}}>
      <Label className="mb-2">{ displayName || name }</Label>
      <div id="editor"
        className={css`
          width: 100%; height: 105px; border: 1px solid grey
        `}
      >
        <Editor
          language={'json'}
          height="100px"
          defaultValue={typeof value === 'object' ? JSON.stringify(value) : value}
          theme={themeMode !== "light" ? 'vs-dark' : 'vs-white'}
          onMount={editorDidMount}
          onChange={(data) => {
            try {
              const valObj = JSON.parse(data);
              if (typeof valObj === 'object' && Object.keys(valObj)?.length > 0) {
                setValue(name, valObj);
              }
              val.current = data;
            }
            catch(err) {
              
            }
          }}
          options={{ contextmenu: true, scrollbar: true }}
          onValidate={
            (errors) => { 
              if (errors?.length === 0) {
                clearErrors(name);
                setValue(name, val.current);
              } else {
                setError(name, {
                  type: "manual",
                  message: "Invalid JSON",
                });
              }
            }
          }
        />
      </div>
      {
        error &&
        <div style={{display:'block'}} className="invalid-feedback">{error.message}</div>
      }
    </FormGroup>
  )
});

export default CodeWidget;