import React from "react";
import { css, cx } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { formatLabel, getWidget } from "../../utils";
import FormInputs from "../FormInputs";
import WithController from "../WithController";

const ArrayWrapper = ({ json, error, control, widgets, screenLayout, themeMode, databaseType, handleUpload, defaultValues, setValue,formMethods }) => {
  const theme = useTheme();
  const { ButtonWidget } = widgets;
  const [items, setItems] = React.useState([]);
  // const [lastIndex, setLastIndex] = React.useState(0);

  const setDefaultValues = () => {
    if (json?.children && typeof json.children === "object") {
      const entries = [];
      defaultValues.forEach((item, index) => {
        const entry = {
          attributes: [
            ...Object.keys(json.children).map((key) => ({
              name: `${json.attrName}.${index}.${key}`,
              attrKey: key,
            })),
          ],
        };
        entries.push(entry);
      });
      setItems([...entries]);
    } else {
      const entries = [];
      defaultValues.forEach((_, index) => {
        entries.push(`${json.attrName}.${index}`);
      });
      setItems([...entries]);
    }
  };

  React.useEffect(() => {
    if(!defaultValues || defaultValues?.length === 0) {
      if (json?.children && typeof json.children === "object") {
        const firstEntry = {
          attributes: [
            ...Object.keys(json.children).map((key) => ({
              name: `${json.attrName}.0.${key}`,
              attrKey: key,
            })),
          ],
        };
        setItems([firstEntry]);
        // setLastIndex(lastIndex + 1);
      } else if (json?.childType) {
        const firstEntry = `${json.attrName}.0`;
        setItems([firstEntry]);
        // setLastIndex(lastIndex + 1);
      }
    } else {
      setDefaultValues();
    }
  }, [json, defaultValues]);

  const addItem = () => {
    // const lastIndexPlusOne = lastIndex + 1;
    const lastIndexPlusOne = items?.length;

    // Array of Object
    if(json.children) {
      const newEntry = {
        attributes: [
          ...Object.keys(json.children).map((key) => ({
            name: `${json.attrName}.${lastIndexPlusOne}.${key}`,
            attrKey: key,
          })),
        ],
      };
      setItems((oldItems) => [...oldItems, newEntry]);
      // setLastIndex(lastIndex + 1);
    }

    // Array of Values
    if (json?.childType) {
      const newValueEntry = `${json.attrName}.${lastIndexPlusOne}`;
      setItems((oldItems) => [...oldItems, newValueEntry]);
      // setLastIndex(lastIndex + 1);
    }
  };

  const removeItem = (index) => {
    if(items.length === 1) {
      return;
    }
    
    // Clear from form Context
    const field = items[index];
    // console.log('field: ', field);

    if (field?.attributes && field?.attributes.length > 0) {
      field?.attributes?.map(item => item.name)?.forEach(item => {
        setValue(item, "");
      });
    }

    if (typeof field === 'string') {
      setValue(field, "");
    }

    setItems((oldItems) => {
      const updatedItems = [...oldItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });

  };

  const baseParentClass =
    screenLayout?.singlePageForm === "threeLine"
      ? "col-span-3"
      : screenLayout?.singlePageForm === "doubleLine"
      ? "col-span-2"
      : "";

  return (
    <div
      className={`${baseParentClass} ${css`
        margin-top: ${!json?.childType ? "23px" : "0px"};
        display: grid;
        gap: 0.75rem;
        border: 1px solid ${error ? '#dc3545' : theme[themeMode]?.borderColor};
        padding: 10px;
        border-radius: 0.25rem;
        grid-column: ${screenLayout?.singlePageForm === "threeLine"
          ? "span 3 / span 3;"
          : screenLayout?.singlePageForm === "doubleLine"
          ? "span 2 / span 2;"
          : ""};
      `}`}
    >
      {!json?.childType && (
        <React.Fragment>
        <h2
          className={css`
            font-size: 20px;
            margin-top: -23px;
            background: ${theme[themeMode]?.backgroundColor};
            display: inline-block;
            width: max-content;
            padding: 0 10px;
          `}
        >
          {formatLabel(json?.attrName)}
        </h2>
        {
          error &&
          <p className={css`
            font-size: .875em;
            margin-top: 0;
            margin-bottom: 0;
            color: #dc3545;
          `}>{ error.message }</p>
        }
        </React.Fragment>
      )}
      {items.map((item, index) => {
        if (json.children) {
          return (
            <div
              className={css`
                display: grid;
                grid-template-columns: repeat(2, minmax(0px, 1fr));
                gap: 1.25rem;
                position: relative;
                padding-right: 70px;
              `}
              key={`${json.attrName}.${index}`}
            >
              {item.attributes.map((attr) => (
                <FormInputs
                  themeMode={themeMode}
                  handleUpload={handleUpload}
                  className={css`
                    margin-bottom: 0px !important;
                  `}
                  json={{
                    ...json?.children[attr.attrKey],
                    attrName: attr.name,
                    label: attr.attrKey,
                  }}
                  key={attr.name}
                  Input={widgets[getWidget(databaseType, json.children[attr.attrKey])] ? widgets[getWidget(databaseType, json.children[attr.attrKey])] : widgets.TextWidget}
                  control={control}
                  formMethods={formMethods}
                />
              ))}
              <div
                onClick={() => removeItem(index)}
                className={css`
                  width: 26px;
                  height: 26px;
                  position: absolute;
                  right: 30px;
                  border: 1px solid ${theme[themeMode]?.borderColor};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  bottom: 0px;
                  border-radius: 0.25rem;
                  font-size: 12px;
                  cursor: pointer;
                `}
              >
                X
              </div>
              {index === items.length - 1 && (
                <button
                  type="button"
                  onClick={addItem}
                  className={css`
                    width: 26px;
                    height: 26px;
                    position: absolute;
                    right: 0;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    bottom: 0;
                    border-radius: 0.25rem;
                    font-size: 16px;
                    cursor: pointer;
                    &:focus {
                      background-color: ${theme[themeMode]?.colors
                        ?.focus} !important; // doesn't work
                      outline: none;
                    }
                    background: ${theme[themeMode]?.colors?.primary};
                    color: ${theme[themeMode]?.colors?.secondary};
                  `}
                >
                  +
                </button>
              )}
            </div>
          );
        }

        return (
          <div
            className={css`
              display: grid;
              gap: 1.25rem;
              position: relative;
              padding-right: 70px;
            `}
          >
            <FormInputs
              themeMode={themeMode}
              handleUpload={handleUpload}
              json={{
                ...json,
                type: json?.childType,
                attrName: item,
                label: json?.attrName,
              }}
              key={item}
              Input={widgets[getWidget(databaseType, { type: json?.childType })]}
              control={control}
              formMethods={formMethods}
            />
            <button
              type="button"
              onClick={() => removeItem(index)}
              className={css`
                width: 26px;
                height: 26px;
                position: absolute;
                right: 30px;
                background-color: transparent;
                border: 1px solid ${theme[themeMode]?.borderColor};
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 0px;
                border-radius: 0.25rem;
                font-size: 12px;
                cursor: pointer;
              `}
            >
              x
            </button>
            {index === items.length - 1 && (
              <button
                onClick={addItem}
                type="button"
                className={css`
                  width: 26px;
                  height: 26px;
                  position: absolute;
                  right: 0;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  bottom: 0;
                  border-radius: 0.25rem;
                  font-size: 16px;
                  cursor: pointer;
                  &:focus {
                    background-color: ${theme[themeMode]?.colors
                      ?.focus} !important; // doesn't work
                    outline: none;
                  }
                  background: ${theme[themeMode]?.colors?.primary};
                  color: ${theme[themeMode]?.colors?.secondary};
                `}
              >
                +
              </button>
            )}
          </div>
        );
      })}
      {/* <ButtonWidget type="button" onClick={addItem}>
        Add +
      </ButtonWidget> */}
    </div>
  );
};

export default ArrayWrapper;
