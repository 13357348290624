import { widgetsMapping } from "../constants";

export const getWidget = (databaseType, item) => {
  let widgetType;

  if (item?.inputType) {
    if (item?.inputType === 'SingleUpload' || item?.inputType === 'MultiUpload') {
      return 'UploadWidget';
    }
    return item?.inputType;
  }

  if (item.enum) {
    widgetType = 'SelectWidget';
  }
  else if (item.type) {
    widgetType = widgetsMapping?.[databaseType]?.[item.type];
    if (!widgetType) {
      widgetType = 'TextWidget';
    }
  }
  else {
    widgetType = 'TextWidget';
  }

  return widgetType;
};

export const getCols = (schema) => {
  let result = 1;
  if (schema?.screenLayout?.singlePageForm) {
    switch (schema?.screenLayout?.singlePageForm) {
      case "singleLine":
        result = 1;
        break;

      case "doubleLine":
        result = 2;
        break;

      case "threeLine":
        result = 3;
        break;

      default:
        result = 1;
        break;
    }
  }

  return result;
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const formatLabel = (string = " ") => {
  const str = string.split(/[^a-zA-Z0-9]/g);
  let a = "";
  str.forEach((b) => {
    a += `${capitalizeFirstLetter(b)} `;
  });

  a = a
    .replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, (s) => s.charAt(0).toUpperCase() + s.substr(1))
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
  return a;
};
