import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormGroup, Label, Input, FormFeedback} from 'reactstrap';

const CheckboxWidget = React.forwardRef(({ name, displayName, error, value, json, ...rest }, ref) => {

  const formContext = useFormContext()
  const setValue = formContext?.setValue ? formContext?.setValue : () => {}

  React.useEffect(() => {
    setValue(name, false);
  }, []);

  return (
    <FormGroup check style={json?.style || {}}>
      <Label check>
        <Input invalid={!!error} type="checkbox" ref={ref} name={name} {...rest} checked={value ? true : false} />{' '}
        { displayName || name } 
      </Label>
      {
        error &&
        <FormFeedback>{ error.message }</FormFeedback>
      }
    </FormGroup>
  );
});

export default CheckboxWidget;