import React from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { useTheme } from '@emotion/react'
import { css } from '@emotion/css'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

const UploadWidget = React.forwardRef(
  (
    { name, displayName, error, handleUpload, json, themeMode, value, ...rest },
    ref
  ) => {

    const theme = useTheme()
    const acceptTypes = React.useMemo(() => {
      if (json?.formValidation) {
        if (Array.isArray(json?.formValidation)) {
          return json?.formValidation.join(', ')
        } else if (typeof json?.formValidation === 'string') {
          return json?.formValidation
        }
      }
      return 'image/*,audio/*,video/*'
    }, [json?.formValidation])

    const formContext = useFormContext()
    const setValue = formContext?.setValue ? formContext?.setValue : () => {}

    React.useEffect(() => {
      return () => {
        if (json?.inputType === 'SingleUpload') {
          setValue(name, '')
        } else {
          setValue(name, [])
        }
      }
    }, []);

    const onDrop = React.useCallback((acceptedFiles) => {
      // Do something with the files
      const formData = new FormData()
      acceptedFiles.forEach((file) => {
        formData.append('file[]', file)
      })

      if (handleUpload) {
        handleUpload(formData, (images) => {
          // For single upload
          if (json?.inputType === 'SingleUpload') {
            if (Array.isArray(images) && images[0] !== '') {
              setValue(name, images[0]);
            } else if (typeof images === 'string' && images !== '') {
              setValue(name, images);
            }
          }

          // Multi Upload
          else if (json?.inputType === 'MultiUpload') {
            if (Array.isArray(images) && images.length > 0) {
              setValue(name, [...value, ...images].filter(Boolean))
            } else if (typeof images === 'string' && images !== '') {
              setValue(name, [...value, images].filter(Boolean))
            }
          }
        })
      }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: acceptTypes,
      multiple: json?.inputType === 'SingleUpload' ? false : true,
      onDrop
    });

    const removeImage = (image) => {
      if (Array.isArray(value)) {
        const removeIndex = value.indexOf(image)
        const valueDup = [...value]
        if (removeIndex !== -1) {
          valueDup.splice(removeIndex, 1)
        }
        setValue(name, valueDup)
      } else {
        setValue(name, '')
      }
    }

    const thumbs = React.useMemo(() => {
      if (value && Array.isArray(value)) {
        return value.map((image) => (
          <div
            style={thumb}
            key={image}
            className={css`
              position: relative;
            `}
          >
            <div
              onClick={() => removeImage(image)}
              className={css`
                border: 1px solid ${theme[themeMode]?.inputBorder};
                background-color: ${theme[themeMode]?.inputBackground};
                cursor: pointer;
                width: 20px;
                height: 20px;
                position: absolute;
                right: -5px;
                top: -5px;
                border-radius: 100%;
                display: flex;
                align-items: center;
              `}
            >
              <svg
                fill={`${theme[themeMode]?.colors.icon}`}
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                width='10px'
                height='10px'
                class='object-contain m-auto'
                viewBox='0 0 24 24'
              >
                <g id='Layer_4'>
                  <path d='M13.909,12.001l8.918-8.92c0.526-0.526,0.526-1.383,0-1.909c-0.243-0.244-0.592-0.384-0.954-0.384s-0.711,0.14-0.954,0.384 L12,10.091L3.082,1.172C2.837,0.928,2.49,0.788,2.127,0.788s-0.71,0.14-0.955,0.384c-0.526,0.526-0.526,1.383,0,1.909l8.918,8.92 l-8.918,8.92c-0.526,0.526-0.526,1.382,0,1.908c0.244,0.244,0.592,0.384,0.955,0.384s0.71-0.14,0.955-0.384L12,13.91l8.919,8.919 c0.255,0.256,0.594,0.396,0.954,0.396s0.699-0.14,0.954-0.396c0.526-0.526,0.526-1.382,0-1.908L13.909,12.001z'></path>
                </g>
                <g id='Layer_2'></g>
              </svg>
            </div>
            <div style={thumbInner}>
              <img src={image} style={img} />
            </div>
          </div>
        ))
      } else if (value && value !== '') {
        return (
          <div
            style={thumb}
            key={value}
            className={css`
              position: relative;
            `}
          >
            <div
              onClick={() => removeImage(value)}
              className={css`
                border: 1px solid ${theme[themeMode]?.inputBorder};
                background-color: ${theme[themeMode]?.inputBackground};
                cursor: pointer;
                width: 20px;
                height: 20px;
                position: absolute;
                right: -5px;
                top: -5px;
                border-radius: 100%;
                display: flex;
                align-items: center;
              `}
            >
              <svg
                fill={`${theme[themeMode]?.colors.icon}`}
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                width='10px'
                height='10px'
                class='object-contain m-auto'
                viewBox='0 0 24 24'
              >
                <g id='Layer_4'>
                  <path d='M13.909,12.001l8.918-8.92c0.526-0.526,0.526-1.383,0-1.909c-0.243-0.244-0.592-0.384-0.954-0.384s-0.711,0.14-0.954,0.384 L12,10.091L3.082,1.172C2.837,0.928,2.49,0.788,2.127,0.788s-0.71,0.14-0.955,0.384c-0.526,0.526-0.526,1.383,0,1.909l8.918,8.92 l-8.918,8.92c-0.526,0.526-0.526,1.382,0,1.908c0.244,0.244,0.592,0.384,0.955,0.384s0.71-0.14,0.955-0.384L12,13.91l8.919,8.919 c0.255,0.256,0.594,0.396,0.954,0.396s0.699-0.14,0.954-0.396c0.526-0.526,0.526-1.382,0-1.908L13.909,12.001z'></path>
                </g>
                <g id='Layer_2'></g>
              </svg>
            </div>
            <div style={thumbInner}>
              <img src={value} style={img} />
            </div>
          </div>
        )
      } else {
        return <div></div>
      }
    }, [value]);

    return (
      <FormGroup style={json?.style || {}}>
        <Label className='mb-2'>{displayName || name}</Label>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div
            className={css`
              border: 1px solid ${theme[themeMode]?.inputBorder};
              background-color: ${theme[themeMode]?.inputBackground};
              padding: 7px 20px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              width: max-content;
              cursor: pointer;
            `}
          >
            <svg
              width='20px'
              height='20px'
              fill={`${theme[themeMode]?.colors.icon}`}
              version='1.1'
              id='Capa_1'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              viewBox='0 0 486.3 486.3'
            >
              <g>
                <g>
                  <path
                    d='M395.5,135.8c-5.2-30.9-20.5-59.1-43.9-80.5c-26-23.8-59.8-36.9-95-36.9c-27.2,0-53.7,7.8-76.4,22.5
  c-18.9,12.2-34.6,28.7-45.7,48.1c-4.8-0.9-9.8-1.4-14.8-1.4c-42.5,0-77.1,34.6-77.1,77.1c0,5.5,0.6,10.8,1.6,16
  C16.7,200.7,0,232.9,0,267.2c0,27.7,10.3,54.6,29.1,75.9c19.3,21.8,44.8,34.7,72,36.2c0.3,0,0.5,0,0.8,0h86
  c7.5,0,13.5-6,13.5-13.5s-6-13.5-13.5-13.5h-85.6C61.4,349.8,27,310.9,27,267.1c0-28.3,15.2-54.7,39.7-69
  c5.7-3.3,8.1-10.2,5.9-16.4c-2-5.4-3-11.1-3-17.2c0-27.6,22.5-50.1,50.1-50.1c5.9,0,11.7,1,17.1,3c6.6,2.4,13.9-0.6,16.9-6.9
  c18.7-39.7,59.1-65.3,103-65.3c59,0,107.7,44.2,113.3,102.8c0.6,6.1,5.2,11,11.2,12c44.5,7.6,78.1,48.7,78.1,95.6
  c0,49.7-39.1,92.9-87.3,96.6h-73.7c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5h74.2c0.3,0,0.6,0,1,0c30.5-2.2,59-16.2,80.2-39.6
  c21.1-23.2,32.6-53,32.6-84C486.2,199.5,447.9,149.6,395.5,135.8z'
                  ></path>
                  <path
                    d='M324.2,280c5.3-5.3,5.3-13.8,0-19.1l-71.5-71.5c-2.5-2.5-6-4-9.5-4s-7,1.4-9.5,4l-71.5,71.5c-5.3,5.3-5.3,13.8,0,19.1
  c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l48.5-48.5v222.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V231.5l48.5,48.5
  C310.4,285.3,318.9,285.3,324.2,280z'
                  ></path>
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
            <span
              className={css`
                margin-left: 10px;
                font-size: 14px;
                color: ${theme[themeMode]?.textColor};
              `}
            >
              Upload file
            </span>
          </div>

        </div>
        {
          ((value && Array.isArray(value)) || (value && value !== ""))
          && <aside style={thumbsContainer}>{thumbs}</aside>
        }

        {error && (
          <FormFeedback style={{ display: 'block' }}>
            {error.message}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }
)

export default UploadWidget
