import React from 'react'
import { Controller } from 'react-hook-form';
import { validationKeys } from '../constants';
import { formatLabel } from '../utils';
import { getValidationData } from '../utils/getValidationData';

const WithController = ({ themeMode, name, displayName, control, Input, json, handleUpload, customValidation, ...others }) => (
    <Controller
        control={control}
        name={name}
        rules={{
          ...getValidationData(json)
        }}
        render={({ field: {value, onChange, ...rest}, fieldState: { error } }) => (
          <Input
            themeMode={themeMode}
            handleUpload={handleUpload}
            name={name}
            json={json}
            onChange={(e) => {
              onChange(e);
              if (customValidation) {
                customValidation();
              } 
            }}
            disabled={!!json?.noEdit}
            error={error}
            displayName={displayName}
            value={value || ""}
            {...rest}
            {...others}
          />
        )} 
      />
  )

export default WithController;
