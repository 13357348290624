export const DB_TYPE = {
  MONGODB: 'MONGODB',
  SQL: 'SQL',
  MYSQL: 'MYSQL',
  POSTGRESQL: 'POSTGRESQL',
};

export const MONGODB = {
  STRING: 'String',
  EMAIL: 'Email',
  NUMBER: 'Number',
  BOOL: 'Boolean',
  ARRAY: 'Array',
  JSON: 'JSON',
  MIXED: 'Mixed',
  DATE: 'Date',
  BUFFER: 'Buffer',
  MAP: 'Map',
  OBJECTID: 'ObjectId',
  VIRTUAL_RELATION: 'virtualRelation',
  SINGLELINE: 'SingleLine',
  MULTILINE: 'MultiLine',
  URL: 'URL',
  DECIMAL: 'Decimal',
  PERCENT: 'Percentage',
  POINT: 'Point',
};

export const POSTGRESQL = {
  STRING: 'STRING',
  TEXT: 'TEXT',
  CHAR: 'CHAR',
  BOOL: 'BOOLEAN',
  INTEGER: 'INTEGER',
  BIGINT: 'BIGINT',
  FLOAT: 'FLOAT',
  REAL: 'REAL',
  DOUBLE: 'DOUBLE',
  DECIMAL: 'DECIMAL',
  DATE: 'DATE',
  DATEONLY: 'DATEONLY',
  UUID: 'UUID',
  UUIDV4: 'UUIDV4',
  BLOB: 'BLOB',
  ENUM: 'ENUM',
  JSON: 'JSON',
  JSONB: 'JSONB',
  ARRAY: 'ARRAY',
  GEOMETRY: 'GEOMETRY',
  GEOGRAPHY: 'GEOGRAPHY',
  RANGE: 'RANGE',
};

export const MYSQL = {
  STRING: 'STRING',
  TEXT: 'TEXT',
  CHAR: 'CHAR',
  BOOL: 'BOOLEAN',
  INTEGER: 'INTEGER',
  BIGINT: 'BIGINT',
  FLOAT: 'FLOAT',
  REAL: 'REAL',
  DOUBLE: 'DOUBLE',
  DECIMAL: 'DECIMAL',
  DATE: 'DATE',
  DATEONLY: 'DATEONLY',
  ENUM: 'ENUM',
  JSON: 'JSON',
  GEOMETRY: 'GEOMETRY',
  GEOGRAPHY: 'GEOGRAPHY',
};

export const SQL = {
  STRING: 'STRING',
  TEXT: 'TEXT',
  CHAR: 'CHAR',
  BOOL: 'BOOLEAN',
  INTEGER: 'INTEGER',
  BIGINT: 'BIGINT',
  FLOAT: 'FLOAT',
  REAL: 'REAL',
  DOUBLE: 'DOUBLE',
  DECIMAL: 'DECIMAL',
  DATE: 'DATE',
  DATEONLY: 'DATEONLY',
  ENUM: 'ENUM',
};

export const ALL_TABLE_TYPES = {
  [DB_TYPE.MONGODB]: MONGODB,
  [DB_TYPE.POSTGRESQL]: POSTGRESQL,
  [DB_TYPE.MYSQL]: MYSQL,
  [DB_TYPE.SQL]: SQL,
};

export const widgetsMapping = {
  [DB_TYPE.MONGODB]: {
    [MONGODB.String]: "TextWidget",
    [MONGODB.OBJECTID]: "TextWidget",
    [MONGODB.NUMBER]: "NumberWidget",
    [MONGODB.DECIMAL]: "NumberWidget",
    [MONGODB.PERCENT]: "PercentageWidget",
    [MONGODB.BOOL]: "CheckboxWidget",
    [MONGODB.DATE]: "DateWidget",
    [MONGODB.ARRAY]: "TextAreaWidget",
    [MONGODB.JSON]: "TextAreaWidget",
    'form': "FormWidget",
    [MONGODB.EMAIL]: "EmailWidget",
    [MONGODB.MIXED]: "TextWidget",
    [MONGODB.BUFFER]: "TextWidget",
    [MONGODB.MAP]: "TextWidget",
    [MONGODB.VIRTUAL_RELATION]: "TextWidget",
    [MONGODB.SINGLELINE]: "TextWidget",
    [MONGODB.MIXED]: "TextAreaWidget",
    [MONGODB.URL]: "TextWidget",
  },
  [DB_TYPE.POSTGRESQL]: {
    [POSTGRESQL.STRING]: 'TextWidget',
    [POSTGRESQL.TEXT]: 'TexAreaWidget',
    [POSTGRESQL.CHAR]: 'TextWidget',
    [POSTGRESQL.BOOL]: 'CheckboxWidget',
    [POSTGRESQL.INTEGER]: 'NumberWidget',
    [POSTGRESQL.BIGINT]: 'NumberWidget',
    [POSTGRESQL.FLOAT]: 'NumberWidget',
    [POSTGRESQL.REAL]: 'NumberWidget',
    [POSTGRESQL.DOUBLE]: 'NumberWidget',
    [POSTGRESQL.DECIMAL]: 'NumberWidget',
    [POSTGRESQL.DATE]: 'DatetimePickerWidget',
    [POSTGRESQL.DATEONLY]: 'DatePicker',
    [POSTGRESQL.UUID]: 'TextWidget',
    [POSTGRESQL.UUIDV4]: 'TextWidget',
    [POSTGRESQL.BLOB]: 'TextWidget',
    [POSTGRESQL.ENUM]: 'SelectWidget',
    [POSTGRESQL.JSON]: 'TextAreaWidget',
    [POSTGRESQL.JSONB]: 'TextAreaWidget',
    [POSTGRESQL.ARRAY]: 'TextAreaWidget',
    [POSTGRESQL.GEOMETRY]: 'TextAreaWidget',
    [POSTGRESQL.GEOGRAPHY]: 'TextAreaWidget',
    [POSTGRESQL.RANGE]: 'TextAreaWidget',
  },
  [DB_TYPE.MYSQL]: {
    [MYSQL.STRING]: 'TextWidget',
    [MYSQL.TEXT]: 'TexAreaWidget',
    [MYSQL.CHAR]: 'TextWidget',
    [MYSQL.BOOL]: 'CheckboxWidget',
    [MYSQL.INTEGER]: 'NumberWidget',
    [MYSQL.BIGINT]: 'NumberWidget',
    [MYSQL.FLOAT]: 'NumberWidget',
    [MYSQL.REAL]: 'NumberWidget',
    [MYSQL.DOUBLE]: 'NumberWidget',
    [MYSQL.DECIMAL]: 'NumberWidget',
    [MYSQL.DATE]: 'DatetimePickerWidget',
    [MYSQL.DATEONLY]: 'DatePicker',
    [MYSQL.ENUM]: 'SelectWidget',
    [MYSQL.JSON]: 'TextAreaWidget',
    [MYSQL.GEOMETRY]: 'TextAreaWidget',
    [MYSQL.GEOGRAPHY]: 'TextAreaWidget',
  },
  [DB_TYPE.SQL]: {
    [SQL.STRING]: 'TextWidget',
    [SQL.TEXT]: 'TexAreaWidget',
    [SQL.CHAR]: 'TextWidget',
    [SQL.BOOL]: 'CheckboxWidget',
    [SQL.INTEGER]: 'NumberWidget',
    [SQL.BIGINT]: 'NumberWidget',
    [SQL.FLOAT]: 'NumberWidget',
    [SQL.REAL]: 'NumberWidget',
    [SQL.DOUBLE]: 'NumberWidget',
    [SQL.DECIMAL]: 'NumberWidget',
    [SQL.DATE]: 'DatetimePickerWidget',
    [SQL.DATEONLY]: 'DatePicker',
    [SQL.ENUM]: 'SelectWidget',
  },
};

export const validationKeys = [
  "minLength",
  "maxLength",
  "required",
  "max",
  "min",
];

export const INVALID_TYPES = [
  "isActive",
  "isDeleted",
  "createdTimestamp",
  "updatedTimestamp",
  "createdAt",
  "updatedAt",
  "addedBy",
  "updatedBy",
];



