import dayjs from "dayjs";
import { DB_TYPE, INVALID_TYPES } from "../constants";
import { isRelation } from "./isRelation";

export const isValidData = (data) => data && data !== "";

export const filterJSONSchema = (_, data) => {
  console.log('data: ', data);
  if (data && Array.isArray(data) && data?.length > 0) {
    const resultObj = {};

    data.forEach(item => {
      if (isValidData(item?.key) && isValidData(item?.value)) {
        resultObj[item?.key] = item?.value;
      }
    });

    if (Object.keys(resultObj)?.length > 0) {
      return resultObj;
    }
  }

  return false;
};

export const filterInvalidTypes = (attributes) => attributes.filter((attr) => !INVALID_TYPES.includes(attr.attrName));

export const filterRelation = (schema, data) => {
  const valKey = schema?.valueAttribute;
  if (valKey && data?.[valKey] && data?.[valKey] !== "") {
    return data[valKey];
  }
  return undefined;
};

export const filterBoolean = (_, data) => {
  if (typeof data === "string") {
    return data === "true";
  }
  return !!data;
};

export const filterDate = (_, data) => {
  const result = dayjs(data).format();
  if (result !== "Invalid Date") {
    return result;
  }
  return undefined;
};

export const cleanArray = (arr) => {
  const result = arr.filter(item => item !== undefined && item !== "");
  if (result.length > 0) {
    return result;
  }
  return undefined;
};

export function cleanObject(obj) {
  const result = {};
  Object.keys(obj).forEach((item) => {
    if (obj[item] !== undefined && obj[item] !== "") {
      result[item] = obj[item];
    }
  });

  if (Object.keys(result).length > 0) {
    return result;
  }
  return undefined;
}

export const filterField = (schema, data, databaseType) => {
  // JSON
  if (schema.type === "JSON") {

    if (schema?.inputType === "CodeWidget" && typeof data === 'string') {
      return JSON.parse(data);
    }

    const filteredObj = databaseType  === DB_TYPE.MONGODB ? cleanObject(data) : filterJSONSchema(schema, data);
    if (filteredObj) {
      return filteredObj;
    }
    return undefined;
  }

  // Array
  if (schema.type === "Array") {
    if (Array.isArray(data) && data.length > 0) {
      // Array of Object
      if (typeof data[0] === "object") {
        const filteredArr = [];
        data.forEach((item) => {
          const newObj = cleanObject(item);
          if (newObj) {
            filteredArr.push(newObj);
          }
        });
        return filteredArr;
      }

      // Array of Values
      return cleanArray(data);
    }

    return undefined;
  }

  // Relationships
  if (isRelation(databaseType, schema)) {
    return filterRelation(schema, data);
  }

  // Boolean
  if (schema.type === "Boolean") {
    return filterBoolean(schema, data);
  }

  // Date
  if (schema.type === "Date") {
    return filterDate(schema, data);
  }

  // Others
  return data;
};
