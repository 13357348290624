import React from 'react';

const LocalFormStateContext = React.createContext(null);
LocalFormStateContext.displayName = 'LocalFormStateContext';

const LocalFormDispatchContext = React.createContext(null);
LocalFormDispatchContext.displayName = 'LocalFormDispatchContext';

// consume only the state
function useLocalFormState() {
  const context = React.useContext(LocalFormStateContext);
  if (!context) {
    throw new Error('useLocalFormState must be within LocalFormProvider');
  }
  return context;
}

// consume dispatch context
function useLocalFormDispatch() {
  const context = React.useContext(LocalFormDispatchContext);
  if (!context) {
    throw new Error('useLocalFormDispatch must be within LocalFormProvider');
  }
  return context;
}

function LocalFormProvider({ children,schema }) {
  const [valueContext,dispatchContext] = React.useState({schema});
  return (
    <LocalFormStateContext.Provider value={valueContext}>
      <LocalFormDispatchContext.Provider value={dispatchContext}>
        {children}
      </LocalFormDispatchContext.Provider>
    </LocalFormStateContext.Provider>
  );
}


// A combo version to use both at once
function useLocalForm() {
  return [useLocalFormState(), useLocalFormDispatch()];
}

export {
  LocalFormStateContext,
  LocalFormDispatchContext,
  LocalFormProvider,
  useLocalFormState,
  useLocalFormDispatch,
  useLocalForm,
};
