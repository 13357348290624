import { DB_TYPE } from "../constants";

export const isRelation = (databaseType, attribute) => {
  if (databaseType === DB_TYPE.MONGODB) {
    if (attribute.type === "ObjectId" || attribute.type === "virtualRelation") {
      return true;
    }
  }

  if (databaseType === DB_TYPE.MYSQL || databaseType === DB_TYPE.SQL || databaseType === DB_TYPE.POSTGRESQL) {
    if (attribute.ref) {
      return true;
    }
  }

  return false;
};