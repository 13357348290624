import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { useTheme } from '@emotion/react';

const SelectWidget = React.forwardRef(({ name, displayName, themeMode, error, json, value, onChange, ...rest }, ref) => {

  const theme = useTheme();
  const [options, setOptions] = React.useState([]);
  const customStyle = {
    control: (provided) => ({
      ...provided,
      border: `1px solid ${theme[themeMode]?.borderColor}`,
      color: `${theme[themeMode]?.textColor}`,
      backgroundColor:`${theme[themeMode]?.backgroundColor}`
    }),
    singleValue: (provided) => ({
      ...provided,
      color: `${theme[themeMode]?.textColor}`,
    }),
    menuList: (provided) => ({
      ...provided,      
      backgroundColor:`${theme[themeMode]?.backgroundColor}`
    }),
    input: (provided) => ({
      ...provided,      
      color: `${theme[themeMode]?.textColor}`,
    }),
    option: (provided,state) => ({
      ...provided,      
      backgroundColor: state.isHovered ? `${theme[themeMode]?.colors?.primary} !important` : state.isFocused ? `${theme[themeMode]?.colors?.primary} !important` : state.isSelected ? `${theme[themeMode]?.colors?.primary} !important` :"transparent !important",
      color: state.isHovered ? `#fff` : state.isFocused ? `#fff` : `${theme[themeMode]?.textColor}`,
    }),
  };

  React.useEffect(() => {
    const modelOptions = Object.keys(json.enum).map((opt) => ({
      value: json.enum[opt],
      label: opt,
    }));
    setOptions([...modelOptions]);
  }, [json]);

  const getValue = React.useCallback((value) => {
    let valueOption = options.find((option) => option['value'] === value);
    if(valueOption === undefined) {
      valueOption = "";
    }
    return valueOption;
  }, [options]);

  const handleChange = (data) => {
    if (!onChange) {
      return;
    } else {
      onChange(data?.['value'] || null);
    }
  };

  const filterOptions = (options) => {
    return Object.keys(options).map((opt) => ({
      value: options[opt],
      label: opt,
    }));
  };

  return (
    <FormGroup style={json?.style || {}}>
      <Label className="mb-2">{ displayName || name }</Label>
      <Select isDisabled={json?.noEdit} styles={customStyle} defaultValue={getValue(value)} onChange={handleChange} value={getValue(value)}  defaultOptions name={name} ref={ref} {...rest} options={filterOptions(json.enum)} />
    </FormGroup>
  )
})

export default SelectWidget
