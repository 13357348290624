import React from "react";
import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { formatLabel, getWidget } from "../../utils";
import FormInputs from "../FormInputs";

const JSONWrapper = ({ json, control, screenLayout, widgets, themeMode, error, databaseType, handleUpload,formMethods}) => {
  const { TextAreaWidget } = widgets;
  const theme = useTheme();

  const baseParentClass = screenLayout?.singlePageForm === "threeLine" 
      ? 'col-span-3' 
        : screenLayout?.singlePageForm === "doubleLine" 
        ? 'col-span-2' 
        : '';

  if (json.children) {
    return(
      <div className={`${baseParentClass} ${css`
        display: grid;
        margin-top: 23px;
        gap: 0.75rem;
        border: 1px solid ${error ? '#dc3545' : theme[themeMode]?.borderColor};
        padding: 10px;
        border-radius: 0.25rem;
        grid-column: ${screenLayout?.singlePageForm === "threeLine" 
          ? 'span 3 / span 3;'
          : screenLayout?.singlePageForm === "doubleLine" ? 'span 2 / span 2;' : ''
        }
      `}`}>
        <h2 className={css`
          font-size: 20px;
          margin-top: -23px;
          background: ${theme[themeMode]?.backgroundColor};
          display: inline-block;
          width: max-content;
          padding: 0 10px; 
        `}>{ formatLabel(json?.attrName) }</h2>
        {
          error &&
          <p className={css`
            font-size: .875em;
            margin-top: 0;
            margin-bottom: 0;
            color: #dc3545;
          `}>{ error.message }</p>
        }
        <div className={css`
          display: grid;
          grid-template-columns: repeat(2, minmax(0px, 1fr));
          gap: 1.25rem; 
          position: relative
        `}>
        {
          Object.keys(json.children).map((key, index) => (
            <FormInputs
              themeMode={themeMode}
              handleUpload={handleUpload}
              json={{
                ...json.children[key],
                attrName: `${json.attrName}.${key}`,
                label: key
              }}
              key={`${key}.input.${index}`} 
              Input={widgets[getWidget(databaseType, json.children[key])] ? widgets[getWidget(databaseType, json.children[key])] : widgets.TextWidget}
              control={control}
              formMethods={formMethods}
            />
          ))
        } 
        </div>
        
      </div>
    ); 
  } 
    return (
      <FormInputs
        json={json}
        Input={TextAreaWidget}
        control={control}
        {...json}
        formMethods={formMethods}
      />
    );
};

export default JSONWrapper;
