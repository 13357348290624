import React from 'react'
import { Button } from 'reactstrap';

const ButtonWidget = ({ children, onClick = () => {}, ...rest }) => {
  return (
    <Button onClick={onClick} color="primary" {...rest}>
      { children }
    </Button>
  )
}

export default ButtonWidget;
