/* eslint-disable no-unused-expressions */
import React from "react";
import {useFormContext} from 'react-hook-form'
import { useLocalFormState } from "../context/localContext";
import { formatLabel } from "../utils";
import { getWatchFields, shouldDisplayInput } from "../utils/filterData";
import WithController from "./WithController";


const FormInputs = ({
  handleUpload,
  control,
  themeMode,
  Input,
  customValidation,
  json: { type, label, attrName, required, ...rest },
  ...others
}) => {
  const {watch,getValues} = others?.formMethods;
  const { showData, hideData, watchFields } = getWatchFields({...others,...{ type, label, attrName, required, ...rest }});
  const watchedFields = watch(watchFields || ['dummy']);
  const show = React.useMemo(
    () => shouldDisplayInput({
        displayOptions:rest?.displayOptions,
        showData,
        hideData,
        getValues
      }),
    [watchedFields,showData,hideData,others,getValues],
  );

  if(!show) return <React.Fragment></React.Fragment>
  return (
  <WithController
    handleUpload={handleUpload}
    themeMode={themeMode}
    displayName={`${formatLabel(label)} ${ required ? '*' : '' }`}
    customValidation={customValidation}
    name={attrName}
    json={{
      attrName,
      label,
      type,
      required,
      ...rest,
    }}
    control={control}
    Input={Input}
    {...others}
  />
)};

export default FormInputs;
