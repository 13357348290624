/* eslint-disable no-unused-expressions */
import { INVALID_TYPES } from "../constants";
import { filterField } from "./filters";

export const filterData = (data, schema, databaseType) => {
  const result = {};
  Object.keys(data)
    .filter((key) => !INVALID_TYPES.includes(key))
    .forEach((formKey) => {
      const schemaAttr = schema.attributes.find(
        (attr) => attr.attrName === formKey
      );
      if (schemaAttr && data[formKey] !== undefined && data[formKey] !== "") {
        const fieldData = filterField(schemaAttr, data[formKey], databaseType);
        if (fieldData !== undefined) {
          result[formKey] = fieldData;
        }
      }
    });
  return result;
};

export const getParentName = (name) => {
  const nameArr = name.split(".");
  nameArr.pop();
  return nameArr.join(".");
};

export const getWatchFields = (field) => {
  const result = { showData: [], hideData: [], watchFields: [] };
  const parentName = getParentName(field?.attrName);
  if (field?.displayOptions?.show) {
    result.showData = Object.keys(field?.displayOptions?.show)?.map((key) => ({
      key: `${parentName ?? `${parentName}.`}${key}`,
      vals: field?.displayOptions?.show[key]
    }));

    result.watchFields = [
      ...result.watchFields,
      ...Object.keys(field?.displayOptions?.show).map(
        (item) => `${parentName ?? `${parentName}.`}${item}`
      )
    ].filter((item, i, ar) => ar.indexOf(item) === i);
  }
  if (field?.displayOptions?.hide) {
    result.hideData = Object.keys(field?.displayOptions?.hide)?.map((key) => ({
      key,
      vals: field?.displayOptions?.hide[key]
    }));

    result.watchFields = [
      ...result.watchFields,
      ...Object.keys(field?.displayOptions?.hide).map(
        (item) => `${parentName ?? `${parentName}.`}${item}`
      )
    ].filter((item, i, ar) => ar.indexOf(item) === i);
  }
  return result;
};

export const shouldDisplayInput = ({
  displayOptions,
  showData,
  hideData,
  getValues
}) => {
  if (!displayOptions) return true;
  if (showData?.length === 0 && hideData?.length === 0) return true
  
  let result = true;
  hideData?.forEach((item) => {
    if (typeof item.vals === "string") {
      if (getValues([item?.key])?.includes(item.vals)) {
        result = false;
      }
    } else if (typeof item.vals === "boolean") {
      if (getValues([item?.key])?.[0] === item.vals) {
        result = false;
      }
    } else if (Array.isArray(item.vals)) {
      if (item.vals.some((value) => getValues([item?.key])?.[0] === value)) {
        result = false;
      }
    }
  });

  showData?.forEach((item) => {
    if (typeof item.vals === "string") {
      if (!getValues([item?.key])?.includes(item.vals)) {
        result = false;
      }
    } else if (typeof item.vals === "boolean") {
      if (getValues([item?.key])?.[0] !== item.vals) {
        result = false;
      }
    } else if (Array.isArray(item.vals)) {
      if (!item.vals.some((value) => getValues([item?.key])?.[0] === value)) {
        result = false;
      }
    }
  });

  return result;
};
