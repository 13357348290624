import React from 'react'
import FormGenerator from './components/FormGenerate.jsx';
import {LocalFormProvider} from './context/localContext'

const FormGenerate = (props) => (
    <LocalFormProvider schema={props?.schema}>
      <FormGenerator {...props}/>
    </LocalFormProvider>
  )

export { FormGenerate };