import { MYSQL, POSTGRESQL, SQL, validationKeys } from "../constants";

export const getValidationData = (json) => {
    const result = {};
    Object.keys(json).forEach((key) => {

      // general validations
      if(validationKeys.includes(key)) {
        if (key === 'required') {
          result[key] = {
            value: json[key],
            message: `Error: ${json?.label} is required`
          };
        }
        else if (key === 'min') {
          result[key] = {
            value: json[key],
            message: `Error: value must be greater than ${json[key]}`
          };
        } else if (key === 'max') {
          result[key] = {
            value: json[key],
            message: `Error: value must be less than ${json[key]}`
          };
        } else {
          result[key] = {
            value: json[key],
            message: `Error: ${key}: ${json[key]}`
          };
        }
      }

      // pattern validation
      if(key === 'pattern') {
        let regex;
        let isValid = true;

        try {
          regex = new RegExp(json[key]);  
        } catch (error) {
          isValid = false;
        }

        if(regex && isValid) {
          result[key] = {
            value: regex,
            message: 'Error: value does not match pattern',
          };
        }
      }
    });

    // UrlInput Validation
    if (json?.inputType && json?.inputType === 'UrlInput') {
      const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      const urlRegex = new RegExp(expression);
      result.pattern = {
        value: urlRegex,
        message: 'Error: invalid url',
      };
    }

    // EmailInput Validation
    if (json?.inputType && json?.inputType === 'EmailWidget') {
      const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;;
      const urlRegex = new RegExp(expression);
      result.pattern = {
        value: urlRegex,
        message: 'Error: invalid email',
      };
    }

    // Sequalize validations
    if ([POSTGRESQL.CHAR, MYSQL.CHAR, SQL.CHAR].includes(json?.type)) {
      result.maxLength = {
        value: 1,
        message: `Error: value must be only 1 character.`
      };
    }

    if ([
      POSTGRESQL.FLOAT, 
      POSTGRESQL.REAL, 
      POSTGRESQL.DOUBLE, 
      POSTGRESQL.DECIMAL, 
      
      MYSQL.FLOAT, 
      MYSQL.REAL,
      MYSQL.DOUBLE,
      MYSQL.DECIMAL,
      
      SQL.FLOAT,
      SQL.REAL,
      SQL.DOUBLE,
      SQL.DECIMAL,
    ].includes(json?.type)) {
      const integerRegex = new RegExp(/[+-]?([0-9]*[.])?[0-9]+/);
      result.pattern = {
        value: integerRegex,
        message: 'Error: invalid float',
      };
    }

    if ([POSTGRESQL.INTEGER, MYSQL.INTEGER, SQL.INTEGER].includes(json?.type)) {
      const integerRegex = new RegExp('^[+-]?[0-9]*$');
      result.pattern = {
        value: integerRegex,
        message: 'Error: invalid number',
      };

      result.max = {
        value: 2147483647,
        message: 'Error: value must be less than 2147483647',
      }
      
      result.min = {
        value: -2147483647,
        message: 'Error: value must be greater than -2147483647',
      }
    }

    if ([POSTGRESQL.BIGINT, MYSQL.BIGINT, SQL.BIGINT].includes(json?.type)) {
      const integerRegex = new RegExp('^[+-]?[0-9]*$');
      result.pattern = {
        value: integerRegex,
        message: 'Error: invalid number',
      };

      result.max = {
        value: 9223372036854775807,
        message: 'Error: value must be less than 9223372036854775807',
      }
      
      result.min = {
        value: -9223372036854775807,
        message: 'Error: value must be greater than -9223372036854775807',
      }
    }

    return result;
  };