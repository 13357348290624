import React from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const DatePickerWidget = React.forwardRef(
  ({ name, displayName, error, json, onChange, value, ...rest }, ref) => {
    return (
      <FormGroup className="datepicker" style={json?.style || {}}>
        <Label className='mb-2'>{displayName || name}</Label>
        <DatePicker
          onChange={onChange}
          placeholderText="Click to select a date"
          selected={value ? new Date(value) : null}
          name={name}
          dateFormat='dd/MM/yyyy'
          showTimeInput={false}
          ref={ref}
        />
        {error && <div style={{display:'block'}} className="invalid-feedback">{error.message}</div>}
      </FormGroup>)
    
  }
)

export default DatePickerWidget
